.section_application_wrapper {
    @apply
    w-full
    px-[80px]
    flex
    justify-center
    items-start
    mb-[150px]
    sm:px-[16px]
}

.section_application_wrapper .section_application {
    @apply
    w-full
    max-w-[740px]
}

.section_application_wrapper .section_application .container {
    @apply
    w-full
    flex
    flex-col
    items-start
    justify-start
}

.section_application_wrapper .section_application .container .title {
    @apply
    w-full
    pb-4
    mb-4
    border-b-c-darkGray
    border-b-[1px]
    flex
    justify-start
    items-center
}

.section_application_wrapper .section_application .container .title h1 {
    @apply
    font-[lobster]
    font-normal
    text-[40px]
    leading-[28px]
    text-c-darkGray
}

.section_application_wrapper .section_application .container form {
    @apply
    w-full
    flex
    flex-col
    items-start
    justify-start
}

.section_application_wrapper .section_application .container form .input_wrapper {
    @apply
    w-full
    flex
    items-start
    justify-start
    flex-col
    mb-[16px]
}

.section_application_wrapper .section_application .container form .input_wrapper label {
    @apply
    flex
    items-start
    justify-start
    text-xl
    mb-[4px]
}

/* .section_application_wrapper .section_application .container form .input_wrapper:not(:last-child)::after {
    content: "*";
    @apply
    left-1
} */

.section_application_wrapper .section_application .container form .input_wrapper .input {
    @apply
    bg-c-purple
    w-full
    p-1
}

.section_application_wrapper .section_application .container form .input_wrapper .input input {
    @apply
    font-[inherit]
    text-xl
    text-[#393e414f]
    h-[49px]
    w-full
    bg-transparent
    border-c-darkGray
    border-[1px]
    p-[14px]
    outline-none
}
.section_application_wrapper .section_application .container form .input_wrapper .input input[type="number"]::-webkit-inner-spin-button {
    display: none;
}
.section_application_wrapper .section_application .container form .input_wrapper .input input:hover {
    @apply
    border-[2px]
}

.section_application_wrapper .section_application .container form .input_wrapper span.error {
    @apply
    text-c-red
    text-sm
}
.section_application_wrapper .section_application .container form .input_wrapper.error .input input{
    @apply
    border-c-red
    !text-c-red
}
.section_application_wrapper .section_application .container form .input_wrapper .input input:focus {
    @apply
    text-c-darkGray
}
.section_application_wrapper .section_application .container form .button_layout {
    @apply
    w-full
    flex
    items-center
    flex-wrap
    justify-between
    gap-[20px]
}
.section_application_wrapper .section_application .container form .button_layout .checkbox_layout {
    @apply
    relative
    flex
    justify-start
    items-start
    flex-col
}

.section_application_wrapper .section_application .container form .button_layout .checkbox_layout input {
    @apply
    absolute
    w-full
    h-full
    left-0
    top-0
    z-[1]
    opacity-0
    cursor-pointer
}
.section_application_wrapper .section_application .container form .button_layout .checkbox_layout span.error {
    @apply
    text-c-red
    text-sm
}
.section_application_wrapper .section_application .container form .button_layout .checkbox_layout .checkbox_wrapper {
    @apply
    flex
    items-start
    justify-start
}
.section_application_wrapper .section_application .container form .button_layout .checkbox_layout .checkbox_wrapper .checkbox {
    @apply
    w-[24px]
    min-w-[24px]
    h-[24px]
    border-c-green
    border-[1px]
    mr-[12px]
    duration-300

    bg-transparent
}

.section_application_wrapper .section_application .container form .button_layout .checkbox_layout input:checked ~ .checkbox_wrapper .checkbox {
    @apply
    bg-c-green
}

.section_application_wrapper .section_application .container form .button_layout .checkbox_layout .checkbox_wrapper .text p{
    @apply
    text-left
    text-xl
    text-c-darkGray
}

.section_application_wrapper .section_application .container form .button_layout .checkbox_layout .checkbox_wrapper .text a {
    @apply
    text-c-darkGreen
    relative
    z-[2]
}

.section_application_wrapper .section_application .container form .button_layout .checkbox_layout .checkbox_wrapper .text a::before {
    content: "";
    @apply
    absolute
    left-0
    bottom-[5px]
    w-full
    bg-c-darkGreen
    h-[1px]
}

.section_application_wrapper .section_application .container form .button_layout button {
    @apply
    font-[inherit]
    text-xl
    text-c-white
    p-[10px]
    h-[49px]
    flex
    items-center
    justify-center
    bg-c-darkOrange
    ml-auto
    w-full
    max-w-[189px]
}