.section_three_wrapper{
    @apply
    w-full
    mb-[150px]
}

.section_three_wrapper .section_three {
    @apply
    w-full
    max-w-[1140px]
}
.section_three_wrapper .section_three .container {
    @apply
    w-full
    flex
    flex-col
    items-center
    justify-start
}

.section_three_wrapper .section_three .title {
    @apply
    mb-[16px]
}

.section_three_wrapper .section_three .title h1 {
    @apply
    font-[lobster]
    font-[400]
    text-[40px]
    text-c-darkGray
    leading-[50px]
    xs:text-[35px]
    xs:leading-[44px]
}

.section_three_wrapper .section_three .explain {
    @apply
    mb-[16px]
    text-left
}
.section_three_wrapper .section_three .explain p {
    @apply
    font-normal
    text-[20px]
    leading-[29px]
    text-c-darkGray
    xs:text-[16px]
    xs:leading-[23px]
}