.header {
  @apply
  /* bg-c-cream */
  /* z-[5] */
  /* bg-[url('../../../assets/pics/header/gif.gif')]
  bg-no-repeat
  bg-cover */
  pb-[117px]
  relative
  h-fit
  overflow-hidden
  z-[1]
}

/* .header::before {
  background-color: rgba(0, 0, 0, 0.2);
  content: '';
  @apply
  absolute
  block
  h-full
  w-full
  z-[1]
} */

/* .header */

.header > nav a {
  @apply
  text-c-white
}

.headerContent {
  @apply
  lg:px-[16px]
  z-[2]
}

.headerContent > .imgContainer {
  @apply
  absolute
  left-0
  top-0
  z-[-1]
  w-full
  h-full
  lg:mt-[50px]
  sm:mt-[100px]
  xs:mt-[160px]
  lg:mb-[36px]
  mx-auto
  /* max-w-[863px] */
  lg:max-w-[80vw]
  xs:max-w-[336px]
}

.imgContainer > img {
  @apply
  w-full
}

.titleContainer {
  @apply
  flex
  flex-row
  justify-between
  gap-x-[50.5px]
  mt-[150px]
  lg:justify-center
}

.titleContainer > h1 {
  @apply
  font-['Lobster']
  text-[80px]
  leading-[100px]
  text-center
  text-c-white
  pb-3
  lg:text-[50px]
  lg:leading-[65px]
  sm:text-[32px]
  sm:leading-[40px]
}

.headerContent > .titleContainer::before {
  content: '';
  @apply
  lg:hidden
  self-start
  inline-block
  align-middle
  h-[5px]
  mt-[50px]
  w-[30vw]
  bg-c-white
  /* bg-c-darkGray */
}

.headerContent > .titleContainer::after {
  content: '';
  @apply
  lg:hidden
  self-end
  align-middle
  inline-block
  h-[5px]
  mb-[50px]
  w-[30vw]
  bg-c-white
  /* bg-c-darkGray */
}

.headerContent > p {
  @apply
  py-3
  text-c-white
}

.headerContent > .btns {
  @apply
  flex
  flex-row
  flex-wrap
  gap-8
  justify-center
  items-center
  pb-[47.5px]
  xs:justify-around
  xs:gap-[10px]
}

.headerContent > .btns > a:last-child {
  @apply
  min-w-[250.5px]
}
