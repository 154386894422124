footer {
  @apply bg-c-lightGreen
  w-full
  px-[16px]
  py-[52px]
  lg:pb-0
  flex
  flex-row
  flex-wrap
  justify-center
  items-center
  md:flex-col
  gap-y-8;
}


footer > div {
  @apply flex
  flex-col
  items-start
  h-[263px]
  md:w-full
  md:h-fit
  md:pb-4;
}

footer > div span,
footer > div a {
  @apply text-lg
  text-c-darkGray;
}

.logoContainer {
  @apply items-center
  border-c-darkGray
  border-r-[1px]
  pr-[34px]
  md:border-r-0
  md:border-b-[1px]
  md:pr-0;
}

.logoContainer > span {
  @apply flex
  items-center
  justify-center
  h-[160px]
  w-[160px]
  pb-4
}

.logoContainer img {
  @apply w-full
  max-w-[270px]
  h-full
  object-contain;
}

.logoContainer > p {
  @apply
  pt-4
  border-t-[1px]
  border-c-darkGray
  text-left
  text-c-darkGray
  md:text-center
  sm:text-left
}

/* .logoContainer > * {
  @apply w-full
  max-w-[193px]
  md:max-w-none;
} */

.middleColumn {
  @apply flex
  flex-row
  justify-between
  border-c-darkGray
  border-r-[1px]
  px-[61px]
  gap-[61px]
  md:border-r-0
  md:border-b-[1px]
  md:pb-8
  sm:px-0;
}

.middleColumn > div {
  @apply pb-0
  flex
  flex-col
  items-start
  gap-8
  md:h-fit
}

@media (max-width: 1105px) {
  .middleColumn {
    @apply border-r-0;
  }

  .contact {
    @apply !pl-0
    !w-[80%]
    !max-w-none;
  }
}

.middleColumn > .divider {
  @apply bg-c-darkGray
  w-[1px]
  h-full
  md:h-[70%]
  md:min-h-[210px];
}

footer > .contact {
  @apply w-full
  max-w-[390px]
  pl-[61px]
  md:!w-full
  md:!pl-[7%]
  sm:!pl-0
}

footer > .contact {
  @apply xl:self-start;
}

footer > .contact > div {
  @apply flex
  flex-row
  items-start
  gap-4
  w-full
  py-[17px]
  lg:pt-0
}

.contact > div:not(:last-child) {
  @apply border-c-darkGray
  border-b-[1px]
  lg:border-b-0
}

.contact > span {
  @apply font-bold
  lg:pb-[17px]
}

.contact span {
  @apply text-left;
}
