.privacy_policy_wrapper {
  @apply w-full px-[80px] sm:px-4 flex items-start justify-center my-[24px]
}

.privacy_policy_wrapper .privacy_policy {
  @apply w-full max-w-[1140px]
}


.privacy_policy_wrapper .privacy_policy .container {
  @apply w-full flex flex-col items-start justify-start text-left gap-6 font-[jost] pt-[50px] pb-[82px]
}

.privacy_policy_wrapper .privacy_policy .container h1 {
  @apply text-2xl text-c-darkGray font-normal sm:text-xl
}

.privacy_policy_wrapper .privacy_policy .container p {
  @apply text-xl text-c-darkGray font-normal sm:text-lg
}

.privacy_policy_wrapper .privacy_policy .container h2 {
  @apply text-[28px] font-bold sm:text-[24px]
}

.privacy_policy_wrapper .privacy_policy .container h3 {
  @apply text-[24px] font-bold
}

.privacy_policy_wrapper .privacy_policy .container ol {
  padding-left: 28px;
}

.privacy_policy_wrapper .privacy_policy .container ol li {
  @apply text-xl text-c-darkGray font-normal sm:text-lg;
  list-style-type: auto;
}