.serviceArea {
  @apply
  flex
  flex-col
  items-center
}

.heading > p {
  @apply
  !max-w-[810px]
}

.serviceArea .mapSection {
  @apply
  w-full
  max-w-[810px]
}

.mapSection > .imgContainer {
  @apply
  mx-auto
  w-[70vw]
  max-w-[565.5px]
  max-h-[295px]
  sm:min-w-[335px]
  sm:min-h-[170px]
  relative
}

.mapSection > img {
  @apply
  w-full
  h-full
}

.mapSection > h2 {
  letter-spacing: 0.355em;
  @apply
  text-[157px]
  leading-[160px]
  text-center
  w-full
  pl-4
  relative
  bottom-[108px]
  lg:text-[135px]
  lg:leading-[130px]
  md:text-[85px]
  md:leading-[95px]
  sm:text-[78px]
  sm:leading-[81px]
  sm:bottom-[80px]
  sm:pl-0
  xs:text-[65px]
}