.main {
  @apply fixed
    top-0
    h-screen
    w-screen
    flex
    items-center
    z-10
    justify-center
    backdrop-blur-md;
}
.main > div {
  @apply bg-c-pampas
  max-w-[1100px]
  w-[85%]
  p-4;
}
.main > div > div {
  @apply border-[1px]
    border-c-red
    p-[32px]
    flex
    flex-col
    items-center
    justify-center
    gap-4;
}
.main > div > div > h3 {
  font-family: lobster;
  @apply text-[20px]
    leading-[25px]
    text-c-red;
}
.main > div > div > p {
  @apply text-[20px]
    leading-[29px]
    text-c-darkGray
    w-full;
}
.main > div > div > button {
  @apply text-[20px]
  text-white
  w-[190px]
  h-[50px]
    bg-c-red;
}
