.mark {
    @apply
    absolute
    w-[32px]
    h-[32px]
    md:w-[24px]
    md:h-[24px]
    sm:w-[20px]
    sm:h-[20px]
    xs:w-[18px]
    xs:h-[18px]
    z-10
    cursor-pointer
    flex
    items-center
    justify-center
    sm:justify-end
  }
  .mark:hover {
    @apply
    z-20
  }
.mark .icon {
    @apply
    w-full
    h-full
    flex
    items-center
    justify-center
    relative
    -z-[1]
  }
  .mark .icon svg {
    @apply
    max-w-full
    max-h-full
    object-contain
  }
.mark .icon svg path {
    @apply
    duration-300
  }
.mark .text {
    @apply
    absolute
    whitespace-nowrap
    bg-c-darkOrange
    h-[23px]
    flex
    items-center
    justify-center
    bottom-[140%]
    opacity-0
    duration-100
    invisible
    z-10
  }
.mark:hover .text {
    @apply
    opacity-100
    visible
  }
  
.mark:hover .icon svg path {
    @apply
    fill-c-darkOrange
    duration-100
  }
  
.mark .text::before {
    content: "";
    @apply
    absolute
    left-0
    right-0
    mx-auto
    w-[14px]
    h-[14px]
    rotate-[-45deg]
    bottom-[-7px]
    bg-c-darkOrange
    -z-[1]
    sm:left-auto
    sm:right-[3px]
  }
  
.mark .text p {
    @apply
    text-left
    text-[15px]
    font-[400]
    leading-[21px]
    px-[8px]
    pb-0
  }
  
  
.mark .text p span {
    @apply
    font-[700]
    text-[15px]
  }