.bodyContainer {
  @apply max-w-[1140px]
  w-[90%]
  my-4
  flex
  flex-col
  items-center
  mx-auto
  sm:gap-[50px];
}

.bodyContainer > img {
  @apply lg:hidden;
}

.bodyContainer > div {
  @apply lg:flex-col
  pb-[24px]
  lg:w-[80%]
}
.oddItemContainer {
  @apply flex
  justify-between
  gap-x-20
  items-center
  sm:flex-col;
}
.oddItemContainer > img {
  @apply w-[390px];
}
.oddItemContainer > p > h3 {
  @apply flex
    justify-between
    border-b-[1px]
    border-c-darkGray
    py-3
    items-end;
}
.oddItemContainer > p > h3 > p > h3 {
  @apply text-c-darkGray
  text-[40px]
  leading-[58px]
  whitespace-nowrap
  my-3
  text-left
  font-bold
  lg:text-[30px]
  lg:leading-[43px];
}
.oddItemContainer > p > h3 > p > h4 {
  @apply text-c-darkGray
  text-[20px]
  mt-3
  text-left
  leading-[29px]
  lg:text-[16px]
  lg:leading-[23px];
}
.oddItemContainer > p > h3 > h3 {
  @apply text-c-darkGray
  text-[100px]
  font-extrabold
  mb-4
  text-opacity-50
  text-right;
}
.oddItemContainer > p > p {
  @apply text-c-darkGray
  text-[20px]
  my-3
  text-left
  lg:text-[16px]
  lg:leading-[23px];
}

.evenItemContainer {
  @apply flex
  flex-row-reverse
  justify-between
  gap-x-20
  items-center
  sm:flex-col;
}
.evenItemContainer > img {
  @apply w-[390px];
}
.evenItemContainer > p > h3 {
  @apply flex
  justify-between
  border-b-[1px]
  border-c-darkGray
  py-3
  items-end;
}
.evenItemContainer > p > h3 > p > h3 {
  @apply text-c-darkGray
  text-[40px]
  my-3
  text-left
  font-bold
  whitespace-nowrap
  leading-[58px]
  lg:text-[30px]
  lg:leading-[43px];
}
.evenItemContainer > p > h3 > p > h4 {
  @apply text-c-darkGray
  text-[20px]
  mt-3
  text-left
  leading-[29px]
  lg:text-[16px]
  lg:leading-[23px];
}
.evenItemContainer > p > h3 > h3 {
  @apply text-c-darkGray
  text-[100px]
  font-extrabold
  mb-4
  text-opacity-50
  text-right;
}
.evenItemContainer > p > p {
  @apply text-c-darkGray
  text-[20px]
  my-3
  text-left
  lg:text-[16px]
  lg:leading-[23px];
}

.lineImg {
  @apply my-[28px]
  sm:hidden;
}
