.sections_job_title_wrapper {
    @apply
    w-full
    flex
    justify-center
    items-start
    mb-[150px]
    pt-[50px]
    px-[80px]
    sm:px-[16px]
}

.sections_job_title_wrapper .sections_job_title {
    @apply
    w-full
    max-w-[740px]
}

.sections_job_title_wrapper .sections_job_title .container {
    @apply
    w-full
    flex
    flex-col
    items-start
    justify-start
}

.sections_job_title_wrapper .sections_job_title .container .title {
    @apply
    w-full
    pb-[16px]
    mb-[16px]
    flex
    justify-start
    items-start
    border-b-c-darkGray
    border-b-[1px]
}

.sections_job_title_wrapper .sections_job_title .container .title h1 {
    @apply
    font-[lobster]
    font-normal
    text-[40px]
    leading-[50px]
    text-c-darkGray
    sm:text-[30px]
}

.sections_job_title_wrapper .sections_job_title .container .list {
    @apply
    w-full
}

.sections_job_title_wrapper .sections_job_title .container .list ul {
    @apply
    w-full
    flex
    items-start
    justify-start
    flex-col
    gap-6
}

.sections_job_title_wrapper .sections_job_title .container .list ul li {
    @apply
    w-full
    text-left
    flex
    items-center
    justify-start
    text-xl
    text-c-darkGray
    sm:text-[18px]
}

.sections_job_title_wrapper .sections_job_title .container .list ul li::before {
    content: "";
    @apply
    w-[10px]
    min-w-[10px]
    h-[10px]
    rounded-full
    mt-[4px]
    bg-c-darkGray
    border-[1.5px]
    border-c-darkGray
    mr-[16px]
}