.whySection {
  @apply max-w-[954px]
  w-full
  px-4
  mx-auto;
}

.reasons {
  @apply 
  flex
  flex-row
  flex-wrap
  justify-between
  items-center
  gap-4
}

@media (max-width: 820px) {
  .reasons {
    @apply
    justify-center
  }
}

.reason {
  @apply p-[10px]
  /* m-5 */
  w-[377px]
  bg-c-pampas;
}
.reason > div {
  @apply border-[1px]
  p-4
  flex
  border-c-darkGray;
}
.reason > div > h3 {
  @apply border-[1px]
  mx-4
  border-c-darkGray;
}
.reason > div > img {
  @apply w-[85px]
  h-[75px];
}
.reason > div > span {
  @apply flex
  flex-col
  items-start
  justify-center;
}
.reason > div > span > h3 {
  @apply text-c-darkGray
  text-[22px]
  font-bold
  sm:text-[18px];
}
.reason > div > span > h4 {
  @apply text-c-darkGray
  text-[20px]
  sm:text-[16px];
}
.contact {
  @apply my-[150px]
  flex
  flex-col
  items-center
  px-4
}
.contact p {
  @apply mb-4;
}
