.about_us_header_wrapepr {
    @apply
    w-full
    mt-[16px]
    bg-c-cream
    p-4
    mb-[150px]
    sm:p-[9px]
}

.about_us_header_wrapepr .about_us_header {
    @apply
    border-c-darkGray
    border-[1px]
}

.about_us_header_wrapepr .about_us_header .image {
    @apply
    w-full
    p-[16px]
    border-b-[1px]
    border-b-c-darkGray
    
}
.about_us_header_wrapepr .about_us_header .image img {
    @apply
    w-full
    max-h-full
    object-contain
}
.about_us_header_wrapepr .about_us_header > .title {
    @apply
    font-[lobster]
}

.about_us_header_wrapepr .about_us_header > .title h1 {
    @apply
    text-[80px]
    text-c-darkGray
    font-[400]
    leading-[100px]
    border-b-c-darkGray
    border-b-[1px]
    flex
    items-center
    justify-center
    sm:text-[50px]
    sm:leading-[62px]
}
.about_us_header_wrapepr .about_us_header > .title h1::before,
.about_us_header_wrapepr .about_us_header > .title h1::after {
    content: "-";
    @apply
    text-[80px]
    sm:text-[50px]
    
}
.about_us_header_wrapepr .about_us_header > .title h1::before {
    @apply
    mr-[40px]
    sm:mr-[25px]
}
.about_us_header_wrapepr .about_us_header > .title h1::after {
    @apply
    ml-[40px]
    sm:ml-[25px]
}

.about_us_header_wrapepr .about_us_header .items {
    @apply
    w-full
    flex
    justify-center
    xl:grid
    xl:grid-rows-2
    xl:grid-cols-2
    sm:grid-cols-1
}