.header_item {
    @apply
    w-full
    flex
    items-center
    justify-start
    px-[24px]
    py-[15px]
    border-c-darkGray
    border-r-[1px]
    sm:border-r-0
    sm:border-b-[1px]
}

.header_item:last-child {
    @apply
    border-r-0
    sm:border-b-0
}
.header_item:nth-child(even) {
    @apply
    xl:border-r-0
}
.header_item:nth-child(1),.header_item:nth-child(2)  {
    @apply
    xl:border-b-[1px]
}

.header_item .image {
    @apply
    h-[70px]
    min-h-[70px]
    w-[70px]
    flex
    items-center
    justify-center
    mr-[12px]
}

.header_item .image img {
    @apply
    max-w-full
    max-h-full
    object-contain
}

.header_item .caption {
    @apply
    flex
    flex-col
    items-start
    justify-center
    gap-y-4
}

/* .header_item .caption .title {
    @apply
    mb-[8px]
} */
.header_item .caption .title p {
    @apply
    font-[700]
    font-[jost]
    leading-[28px]
    text-[20px]
    text-c-darkGray
}
.header_item .caption .explain p{
    @apply
    font-[400]
    font-[jost]
    text-[20px]
    leading-[29px]
    text-c-darkGray
    whitespace-nowrap
}