.desktopNavContainer {
  @apply 
  flex
  flex-row
  items-center
  gap-[60.5px]
  lg:gap-0
  /* px-[150px] */
  mx-auto
  max-w-[1140px]
  w-[80vw]
  pt-[47px]
  lg:justify-between
}
.desktopNavContainer > a {
  @apply
  font-['Lobster']
  font-normal
  text-3xl
  text-c-black
  leading-[37.5px]
  z-[2]
} 

.desktopNav {
  @apply
  lg:hidden
  relative
  flex
  flex-row
  items-center
  gap-[68px]
}

.desktopNav a,
.popupHeader a {
  @apply
  relative
  whitespace-nowrap
  font-normal
  text-c-darkGray
  text-xl
  leading-[28.9px]
  z-[1]
}

.desktopNav a::before,
.popupHeader a::before {
  content: "";
  @apply
  w-[48px]
  h-[48px]
  absolute
  -z-[1]
  /* duration-100 */
  bg-c-lightGreen
  rounded-full
  bottom-[-10px]
  left-[-18.5px]
  opacity-0
}

.popupHeader a::before {
  @apply
  left-[10.5px]
}

.selected {
  @apply
  !font-semibold
  z-[8]
}

.selected::before {
  @apply
  !opacity-100
}

.selected::before {
  content: '';
  @apply
  bg-c-lightGreen
  rounded-full
  h-[48px]
  w-[48px]
  block
  absolute
  left-[-18px]
  top-[-7px]
  lg:left-[10px]
  lg:top-[12px]
}

.navbarBtn {
  @apply
  hidden
  lg:flex
  h-[48px]
  w-[48px]
  rounded-full
  bg-c-lightGreen
  p-[12px]
  z-10
}

.popupHeader {
  transition-timing-function: cubic-bezier(0.16, 0.59, 1, 1) !important;
  @apply
  hidden
  lg:flex
  invisible
  fixed
  bottom-0
  left-0
  z-50
  h-full
  max-w-[0]
  w-full
  bg-c-popupBackground
  p-4
  overflow-hidden
  transition-all
  duration-[.5s]
}

.popupHeader.active {
  @apply
  max-w-[225px]
  visible
}

.popupHeader > div {
  @apply
  flex
  flex-col
  items-center
  w-full
  border-c-darkGray
  border-[1px]
}

.popupHeader > div > a {
  @apply
  border-c-darkGray
  border-b-[1px]
  w-full
  py-[20px]
  pl-[24px]
  text-left
}

.mobileNavbar > a {
  @apply
  !text-c-darkGray
}