.industriesSection {
  @apply
  flex
  flex-col
  justify-center
  items-center
}

.industriesSection .industries {
  @apply
  flex
  flex-row
  flex-wrap
  justify-center
  gap-x-[150px]
  gap-y-4
  w-full
  max-w-[954px]
}