.containedBtn {
  @apply
  flex
  flex-row
  justify-center
  items-center
  gap-[13.5px]
  px-[40px]
  py-[10px]
  text-c-white
  bg-c-green
  text-xl
  leading-[32px]
  min-w-[189px]
  duration-100
  hover:bg-c-darkGreen
  hover:font-semibold
}

.containedBtn.full {
  @apply
  w-full
}

button.containedBtn {
  @apply
  bg-c-darkOrange
  /* hover:bg-c-darkGreen */
  hover:font-semibold
  hover:bg-c-darkOrange
}