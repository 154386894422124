.home {
  @apply
  flex
  flex-col
  /* gap-[150px] */
  bg-c-background
}

.home h2 {
  @apply
  font-['Lobster']
  font-normal
  text-[40px]
  leading-[50px]
  pb-4
  text-c-darkGray
}

.home section:not(:last-child) {
  @apply
  text-center
  mb-[150px]
  px-4
}

.home section div > p {
  @apply
  w-full
  max-w-[750px]
  mx-auto
  pb-4
}