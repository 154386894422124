.join_us_header_wrapper {
    @apply
    w-full
    flex
    flex-col
    items-center
    justify-start
    mt-[38px]
    mb-[100px]
}

.join_us_header_wrapper .title {
    @apply
    flex
    items-center
    justify-center
    w-full
    mb-[90px]
    lg:flex-col
    lg:px-8
}

.join_us_header_wrapper .title::before,
.join_us_header_wrapper .title::after {
    content: "";
    @apply
    bg-c-darkGray
    h-[5px]
    w-full
    lg:hidden
}

.join_us_header_wrapper .title::before {
    @apply
    mr-[86px]
}
.join_us_header_wrapper .title::after {
    @apply
    ml-[86px]
}

.join_us_header_wrapper .title .text h1 {
    @apply
    text-[90px]
    font-[lobster]
    font-[400]
    leading-[112px]
    text-c-darkGray
    md:text-6xl
    whitespace-nowrap
    lg:whitespace-normal
}

.join_us_header_wrapper .title .image {
    @apply
    w-[308px]
    min-w-[308px]
    mx-[38px]
    flex
    items-center
    justify-center
    md:mx-0
    md:my-3
}

.join_us_header_wrapper .title .image img {
    @apply
    max-w-full
    max-h-full
    object-contain
}
.join_us_header_wrapper .explain_wrapper {
    @apply
    w-full
    px-[80px]
    flex
    flex-col
    justify-start
    items-center
    gap-y-4
    sm:px-4
}
.join_us_header_wrapper .explain_wrapper .explain_title {
    @apply
    font-[lobster]
    text-[40px]
    leading-[50px]
    font-normal
}
.join_us_header_wrapper .explain_wrapper .explain_title h1 {
    @apply
    text-c-darkGray
}
.join_us_header_wrapper .explain {
    @apply
    w-full
    max-w-[740px]
    text-center
    flex
    flex-col
    items-center
    justify-start
    gap-y-4
}

.join_us_header_wrapper .explain p {
    @apply
    text-[20px]
    text-c-darkGray
    leading-[29px]
    font-normal
}

.join_us_header_wrapper .explain span {
    @apply
    text-[30px]
    leading-[50px]
    font-[600]
    text-center
    text-c-darkGreen
}

.join_us_header_wrapper .buttons {
  @apply
  w-full
  flex
  justify-evenly
  items-center
  max-w-[800px]
  gap-6
}

.join_us_header_wrapper .buttons a:nth-child(1) {
  @apply
  text-c-darkGreen
}
.join_us_header_wrapper .buttons a:nth-child(2) {
  @apply
  text-c-white
}