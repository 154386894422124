@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Jost";
  src: url("../public/Fonts/Jost-Thin.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Jost";
  src: url("../public/Fonts/Jost-Regular.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Jost";
  src: url("../public/Fonts/Jost-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Jost";
  src: url("../public/Fonts/Jost-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Jost";
  src: url("../public/Fonts/Jost-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "Lobster";
  src: url("../public/Fonts/Lobster-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Jost';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  @apply
  bg-[#F4F1EC]
  text-c-darkGray
}

p {
  @apply
  font-normal
  text-xl
  leading-[29px]
  text-c-black
}