.greenBoxes {
  @apply
  relative
  bottom-[65.5px]
  sm:bottom-[56.5px]
  flex
  flex-row
  flex-wrap
  justify-evenly
  items-center
  z-[5]
  px-2
  sm:px-0
  /* gap-[128.5px] */
  gap-x-[40px]
  gap-y-[40px]
  md:gap-x-[17px]
  md:gap-y-[17px]
  sm:flex-col
}

.greenBoxes > .greenBox {
  @apply bg-[url('../../../assets/pics/header/green-box-desktop.svg')]
  sm:bg-[url('../../../assets/pics/header/green-box-mobile.svg')]
  bg-no-repeat
  text-c-darkGray
  flex
  flex-col
  justify-center
  items-center
  min-w-[294px]
  min-h-[131px]
  sm:w-full
  sm:max-w-[342px]
  sm:min-h-[113px]
  sm:h-[113px]
}

.greenBoxes > .greenBox > .number {
  @apply
  font-bold
  text-[40px]
  leading-[58px]
  sm:text-[35px]
  sm:leading-[50px]
}

.greenBoxes > .greenBox > .txt {
  @apply
  font-normal
  text-[25px]
  leading-[36px]
  sm:text-[18px]
  sm:leading-[26px]
}

sup {
  @apply
  text-[23px]
}