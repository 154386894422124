.modalWrapper {
  @apply
  opacity-0
  invisible
  duration-300
  fixed
  inset-0
  /* relative */
  flex
  justify-center
  items-center
  z-40
  px-4
  mx-4
}

.modalWrapper.show {
  @apply
  opacity-100
  visible
}

.modalContainer {
  max-height: calc(100vh - 36px);
  @apply
  bg-c-darkSalmon
  text-left
  p-4
  w-[80vw]
  md:w-full
  overflow-y-auto
  /* max-h-[96vw] */
  /* mx-4 */
}

.modalContainerWrapper {
  @apply
  fixed
  z-10
  mx-4
}

.modalContainer::-webkit-scrollbar {
  @apply
  hidden
}

.modal {
  @apply
  flex
  flex-col
  justify-center
  items-start
  gap-4
  p-4
  border-[1px]
  border-c-darkGray
}

.modal > div {
  @apply
  border-b-[1px]
  border-c-darkGray
  pb-4
}



.modal p {
  @apply
  text-[20px]
}

.modal > .heading {
  @apply
  flex
  flex-row
  md:flex-col
  /* items-start */
  self-stretch
  gap-4
}

.modal > .heading > .imgContainer {
  @apply
  flex
  justify-center
  border-r-[1px]
  border-c-darkGray
  pr-4
  md:p-0
  md:border-none
}

/* .modal > .heading > p {
  @apply
  pt-[20px]
} */

.modal > .heading > .headingTxt {
  @apply
  flex
  flex-col
  justify-center
  gap-[20px]
  w-full
}

.headingTxt  > h4 {
  @apply
  text-[32px]
  font-extrabold
  pb-[20px]
  border-b-[1px]
  border-c-darkGray
}

.backdrop {
  /* backdrop-filter: blur(3px); */
  background: rgba(0, 0, 0, 0.2);
  @apply
  opacity-0
  duration-100
  fixed
  w-full
  h-full
}

.modalWrapper.show .backdrop {
  @apply
  opacity-100
}