.section_two_wrapper{
    @apply
    p-4
    bg-c-purple
    w-full
    mb-[150px]
    sm:p-[9px]
}

.section_two_wrapper .section_two {
    @apply
    border-[1px]
    border-c-darkGray
}

.section_two_wrapper .section_two .title {
    @apply
    w-full
    text-center
    p-[16px]
    border-b-c-darkGray
    border-b-[1px]
}

.section_two_wrapper .section_two .title h1 {
    @apply
    font-[400]
    font-[lobster]
    text-[40px]
    text-c-darkGray
    leading-[50px]
    sm:text-[23px]
    sm:leading-[29px]
}

.section_two_wrapper .section_two .image {
    @apply
    p-[16px]
    border-b-c-darkGray
    border-b-[1px]
}

.section_two_wrapper .section_two .image img {
    @apply
    max-w-full
    max-h-full
    object-contain
}

.section_two_wrapper .section_two .explain {
    @apply
    p-4
    text-left
}

.section_two_wrapper .section_two .explain p {
    @apply
    font-[400]
    text-[20px]
    text-c-darkGray
    leading-[29px]
    xs:text-[16px]
    xs:leading-[23px]
}