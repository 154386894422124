.contactUs {
  /* @apply bg-c-lightGreen; */
}
.contactUs h2 {
  @apply font-['Lobster']
    font-normal
    text-[40px]
    leading-[50px]
    pb-4
    text-c-darkGray;
}
.head {
  @apply flex
  flex-col
  items-center
  gap-[10px];
}
.head h2 {
  @apply flex
  items-center
  justify-between
  w-full;
}
.head h2::before {
  content: "";
  @apply inline-block
    align-middle
    h-[5px]
    grow
    bg-c-darkGray;
}
.head h2::after {
  content: "";
  @apply align-middle
    inline-block
    h-[5px]
    /* mb-[50px] */
    /* w-[35vw] */
    grow
    bg-c-darkGray;
}
.head > h2 > span {
  @apply max-w-[484px];
}
.botContainer {
  @apply flex
  my-[150px]
  w-full
  px-4
    mx-auto
    gap-[24px]
    max-w-[1200px]
    text-[20px]
    sm:flex-col-reverse
    sm:text-[16px]
    sm:my-[75px];
}
.formContainer {
  @apply w-[50%]
  flex
  flex-col
  gap-4
  sm:w-full;
}
.input {
  @apply flex
    flex-col
    w-full
    justify-between
    gap-1
    items-start;
}
.inputContainer {
  @apply p-1
  w-full
  bg-c-purple
  flex
}
.inputContainer input {
  @apply border-[1px]
  h-[49px]
  outline-none
  w-full
  p-[10px]
  text-c-darkGray
  bg-inherit
    border-c-darkGray;
}

.input > label::after {
  content: "*";
  @apply
  left-1
}
.inputContainer textarea {
  @apply h-[178px]
  outline-none
  border-[1px]
  border-c-darkGray
  text-c-darkGray
  p-[10px]
  bg-inherit
  w-full
  resize-none
}
.action {
  @apply flex
    items-center
    justify-between
    w-full
    sm:flex-col
    sm:items-start
    sm:gap-4;
}
.action button {
  @apply w-[190px]
    h-[50px]
    text-c-white
    bg-c-darkOrange
    sm:w-full;
}
.action > span > span {
  @apply ml-2;
}
.action span a {
  @apply underline
  text-c-green;
}
.detailContainer {
  @apply w-[50%]
  flex
  text-left
  justify-between
  items-start
  flex-col
  mt-[33px]
  mb-[65px]
  text-c-darkGray
  leading-[42px]
  sm:w-full
  sm:gap-[50px];
}
.kontaktContainer {
  @apply flex
    flex-col
    gap-[32px];
}
.kontaktContainer h3 {
  @apply font-bold;
}
.kontaktContainer > div {
  @apply flex
  gap-4
  leading-[29px]
  items-start
  justify-start
}

.kontaktContainer > div > img {
  @apply
  mt-[3px]
}

.divider {
  @apply bg-c-darkGray
    h-[1px]
    w-full;
}
.talkToUs {
  @apply hidden
    sm:block;
}
.errorText {
  @apply text-c-red
  text-left;
}
.checkbox_layout {
  @apply relative
  flex
  justify-start
  items-start
  flex-col;
}

.checkbox_layout input {
  @apply absolute
  w-full
  h-full
  left-0
  top-0
  z-[1]
  opacity-0
  cursor-pointer;
}
.checkbox_layout span.error {
  @apply text-c-red
  text-sm;
}
.checkbox_layout .checkbox_wrapper {
  @apply flex
  items-start
  justify-start;
}
.checkbox_layout .checkbox_wrapper .checkbox {
  @apply w-[24px]
  min-w-[24px]
  h-[24px]
  border-c-green
  border-[1px]
  mr-[12px]
  duration-300

  bg-transparent;
}

.checkbox_layout input:checked ~ .checkbox_wrapper .checkbox {
  @apply bg-c-green;
}

.checkbox_layout .checkbox_wrapper .text p {
  @apply text-left
  text-xl
  text-c-darkGray;
}

.checkbox_layout .checkbox_wrapper .text a {
  @apply text-c-darkGreen
  relative
  z-[2];
}

.checkbox_layout .checkbox_wrapper .text a::before {
  content: "";
  @apply absolute
  left-0
  bottom-[5px]
  w-full
  bg-c-darkGreen
  h-[1px];
}
