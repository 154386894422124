@import '../../../pages/home/home.module.css';

.whatSection {
  @apply
  flex
  flex-col
  justify-center
  items-center
  gap-[150px]
  pt-[84.5px]
  whitespace-break-spaces
}

/* .whatSection .heading {
  @apply
  w-[70vw]
} */

.whatSection .boxListSection {
  @apply
  flex
  flex-col
  justify-center
  items-center
  w-full
  /* px-4 */
}

.boxListSection ul {
  @apply
  flex
  flex-row
  flex-wrap
  justify-center
  /* items-center */
  gap-4
  w-full
  max-w-[1140px]
}