.application_section_careers_wrapper {
    @apply
    w-full
    flex
    justify-center
    items-start
    mb-[150px]
    px-[80px]
    sm:px-[16px]
}

.application_section_careers_wrapper .application_section_careers {
    @apply
    w-full
    max-w-[740px]
    p-4
    flex
    bg-c-pampas
    sm:p-3
}

.application_section_careers_wrapper .application_section_careers .container {
    @apply
    w-full
    flex
    flex-col
    items-start
    justify-start
    border-[1px]
    border-c-darkGray
}

.application_section_careers_wrapper .application_section_careers .container .title {
    @apply
    border-b-c-darkGray
    border-b-[1px]
    w-full
    p-4
    flex
    items-center
    justify-start
    sm:p-3
}

.application_section_careers_wrapper .application_section_careers .container .title h1 {
    @apply
    font-[lobster]
    text-[40px]
    text-left
}


.application_section_careers_wrapper .application_section_careers .container .personal {
    @apply
    flex
    w-full
    xs:flex-col-reverse
}

.application_section_careers_wrapper .application_section_careers .container .personal .name {
    @apply
    p-4
    flex
    items-center
    justify-center
    border-r-[1px]
    border-b-[1px]
    border-c-darkGray
    sm:p-3
    xs:border-t-0
    xs:border-r-0
    xs:justify-start
}
.application_section_careers_wrapper .application_section_careers .container .personal .name p {
    @apply
    text-[30px]
    font-[600]
    whitespace-nowrap
    md:whitespace-normal
    md:text-[22px]
    xs:text-left
}
.application_section_careers_wrapper .application_section_careers .container .personal .information {
    @apply
    w-full
    flex
    flex-col
    items-start
    justify-start
}

.application_section_careers_wrapper .application_section_careers .container .personal .information .item {
    @apply
    w-full
    p-4
    flex
    items-center
    justify-start
    border-b-c-darkGray
    border-b-[1px]
    sm:p-3
    xs:border-b-0
}

.application_section_careers_wrapper .application_section_careers .container .personal .information .item .image {
    @apply
    w-[24px]
    min-w-[24px]
    h-[24px]
    flex
    mr-[16px]
}

.application_section_careers_wrapper .application_section_careers .container .personal .information .item .text p {
    @apply
    text-[20px]
    md:text-[16px]
}

.application_section_careers_wrapper .application_section_careers .container .explain_title{
    @apply
    p-4
    w-full
    border-b-[1px]
    border-b-c-darkGray
    sm:p-3
}

.application_section_careers_wrapper .application_section_careers .container .explain_title p {
    @apply
    text-c-darkGreen
    text-[30px]
    leading-[36px]
    font-medium
    sm:text-[25px]
}

.application_section_careers_wrapper .application_section_careers .container .explain {
    @apply
    p-4
    w-full
    sm:p-3
}

.application_section_careers_wrapper .application_section_careers .container .explain p {
    @apply
    text-left
    text-[#000]
    text-[23px]
    md:text-[19px]
}