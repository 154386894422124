.monthly_gross_wrapper {
    @apply
    w-full
    flex
    justify-center
    items-start
    mb-[150px]
    px-[80px]
    sm:px-[16px]
}

.monthly_gross_wrapper .monthly_gross {
    @apply
    w-full
    max-w-[740px]
}

.monthly_gross_wrapper .monthly_gross .container {
    @apply
    w-full
    flex
    flex-col
    items-start
    justify-start
}

.monthly_gross_wrapper .monthly_gross .container .title {
    @apply
    w-full
    pb-[16px]
    mb-[16px]
    flex
    justify-start
    items-start
    border-b-c-darkGray
    border-b-[1px]
}

.monthly_gross_wrapper .monthly_gross .container .title h1 {
    @apply
    font-[lobster]
    font-normal
    text-[40px]
    leading-[50px]
    text-left
    text-c-darkGray
    sm:text-[30px]
}

.monthly_gross_wrapper .monthly_gross .container .explain {
    @apply
    flex
    items-center
    justify-start
    sm:flex-col
    sm:items-start
}

.monthly_gross_wrapper .monthly_gross .container .explain .money {
    @apply
    flex
    items-end
    justify-start
    mr-[18px]
    
}

.monthly_gross_wrapper .monthly_gross .container .explain .money p {
    @apply
    text-c-darkGray
    text-[40px]
    font-[700]
}

.monthly_gross_wrapper .monthly_gross .container .explain .money p span {
    @apply
    text-[26px]
}

.monthly_gross_wrapper .monthly_gross .container .explain .month p{
    @apply
    text-[15px]
}