.outlinedBtn {
  @apply
  relative
  flex
  flex-row
  justify-center
  items-center
  gap-[13.5px]
  px-[40px]
  /* py-[8.4px] */
  py-[10px]
  text-c-darkGreen
  bg-transparent
  border-[2px]
  border-c-darkGreen
  text-xl
  min-w-[189px]
  hover:border-c-darkGreen
  hover:text-c-darkGreen
  hover:font-semibold
}

header .outlinedBtn {
  @apply
  text-c-white
  border-c-green
  hover:text-c-white
}

.outlinedBtn::before {
  content: '';
  @apply
  absolute
  w-full
  h-full
  left-0
  top-0
  border-c-darkGreen
  border-0
  duration-100
}

.outlinedBtn:hover::before {
  @apply
  border-[2px]
}

.outlinedBtn.full {
  @apply
  w-full
}

/* button.outlinedBtn {
  @apply
  bg-c-darkOrange
} */