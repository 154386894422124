.impressum_wrapper {
    @apply
    w-full
    flex
    justify-center
    items-start
    mb-[150px]
}

.impressum_wrapper .impressum {
    @apply
    w-full
    max-w-[1140px]
}

.impressum_wrapper .impressum .container {
    @apply
    w-full
    
}

.impressum_wrapper .impressum .container .box_wrapper {
    @apply
    w-full
    p-4
    flex
    items-center
    justify-center
    bg-c-pampas
}

.impressum_wrapper .impressum .container .box_wrapper .box {
    @apply
    w-full
    border-[1px]
    border-c-darkGray
    border-b-0
}

.impressum_wrapper .impressum .container .box_wrapper .box .title {
    @apply
    p-[10px]
    border-b-c-darkGray
    text-center
    border-b-[1px]
    flex
    items-center
    justify-center
}

.impressum_wrapper .impressum .container .box_wrapper .box .title h1 {
    @apply
    text-[40px]
    font-[400]
    font-[lobster]
    text-c-darkGray
}

.impressum_wrapper .impressum .container .box_wrapper .box .items {
    @apply
    w-full
    grid
    grid-rows-2
    grid-cols-2
    text-left
    border-b-c-darkGray
    sm:grid-cols-1
}
.impressum_wrapper .impressum .container .box_wrapper .box .items .item {
    @apply
    py-[20px]
    px-[10px]
    border-b-[1px]
    border-b-c-darkGray
    
}
.impressum_wrapper .impressum .container .box_wrapper .box .items .item:nth-child(odd) {
    @apply
    border-r-[1px]
    border-r-c-darkGray
    sm:border-r-0
}