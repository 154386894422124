.who_we_are {
    @apply
    w-full
    max-w-[267px]
    bg-c-pampas
    p-[16px]
}

.who_we_are .image {
    @apply
    w-full
    pb-2
    border-b-c-darkGray
    border-b-[1px]
    mb-[18px]
}
.who_we_are .caption {
    @apply
    flex
    flex-col
    items-center
    justify-start
    w-full
    min-h-[111px]
    border-b-c-darkGray
    border-b-[1px]
}
.who_we_are .name {
    @apply
    mb-[9px]
    text-center
}

.who_we_are .name p {
    @apply
    font-[600]
    text-[24px]
    leading-[35px]
}

.who_we_are .position {
    @apply
    mb-[9px]
}

.who_we_are .position p {
    @apply
    font-[400]
    text-[20px]
    leading-[29px]
    text-center
}

.who_we_are .send_button{
    @apply
    w-full
    flex
    items-center
    justify-center
    pt-[9px]
}

.who_we_are .send_button a {
    @apply
    flex
    items-center
    justify-center
    relative
}

.who_we_are .send_button a .image {
    @apply
    w-[24px]
    h-[24px]
    flex
    items-center
    justify-center
}

.who_we_are .send_button a .image img {
    @apply
    max-w-full
    max-h-full
    object-contain
}

.who_we_are .send_button a .email_container {
    @apply
    absolute
    top-[110%]
    bg-c-lightGreen
    p-2
    flex
    items-center
    justify-center
    z-[1]
    duration-300
    h-[23px]
    opacity-0
    invisible
}
.who_we_are .send_button a:hover .email_container {
    @apply
    top-[130%]
    opacity-100
    visible
}
.who_we_are .send_button a .email_container p {
    @apply
    text-[16px]
    font-[400]
    leading-[23px]
    text-c-darkGray
}
.who_we_are .send_button a .email_container::before {
    content: "";
    @apply
    w-[14px]
    h-[14px]
    rotate-[-45deg]
    bg-c-lightGreen
    top-[-7px]
    -z-[1]
    absolute
}