.industryItem {
  @apply flex
  relative
  flex-col
  gap-[34px]
  w-full
  max-w-[402px]
  border-b-[2px]
  border-c-darkGray;
}

/* .industryItem::after {
  content: "";
  @apply h-[2px]
  w-full
  bg-c-darkGray
  left-0
  bottom-[70px]
  absolute;
} */

.industryItem::before {
  content: "";
  @apply h-full
  w-[2px]
  bg-c-darkGray
  right-[70px]
  top-0
  absolute;
}

.upperPart {
  @apply flex
  flex-row
  relative
  /* overflow-hidden */
  /* gap-[40px]; */;
}

.upperPart > .imgContainer {
  @apply max-w-[304px]
  w-[65vw]
  pb-[19.5px]
  xs:min-w-[240px];
}

.upperPart > img {
  @apply w-full;
}

/* .upperPart .titleContainer {
  height: 183px;
  width: 58px;
  @apply relative
  overflow-hidden
  h-[58px]
  w-[50px]
} */

.upperPart h4 {
  writing-mode: vertical-lr;
  @apply absolute right-0 bottom-0
  font-semibold 
  text-[28px] 
  leading-[58px] 
  rotate-[180deg]
  pt-[12.5px]
  whitespace-nowrap
  sm:text-[30px]
  sm:leading-[43px];
}

.industryItem > p {
  @apply max-w-[304px]
  w-[65vw]
  xs:min-w-[240px]
  sm:text-[16px]
  sm:leading-[23px];
}
