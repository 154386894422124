.section_one_wrapper{
    @apply
    w-full
    mb-[150px]
}

.section_one_wrapper .section_one {
    @apply
    w-full
    max-w-[1140px]
}
.section_one_wrapper .section_one .container {
    @apply
    w-full
    flex
    flex-col
    items-center
    justify-start
}
.section_one_wrapper .section_one .title {
    @apply
    mb-[16px]
}

.section_one_wrapper .section_one .title h1 {
    @apply
    font-[lobster]
    font-[400]
    text-[40px]
    text-c-darkGray
    leading-[50px]
    xs:text-[35px]
    xs:leading-[44px]
}

.section_one_wrapper .section_one .explain {
    @apply
    mb-[16px]
    text-left
}
.section_one_wrapper .section_one .explain p {
    @apply
    font-normal
    text-[20px]
    leading-[29px]
    text-c-darkGray
    xs:text-[16px]
    xs:leading-[23px]
}
.section_one_wrapper .section_one .items {
    @apply
    w-full
    flex
    justify-center
    gap-6
    flex-wrap
}