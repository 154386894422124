.packItem {
  @apply
  bg-c-pampas
  flex
  flex-col
  items-start
  p-4
  w-full
  max-w-[272px]
  sm:max-w-[75vw]
  xs:max-w-none
  /* h-[479px] */
}

.packItem h4 {
  @apply
  font-bold
  text-[23px]
  leading-[32px]
  border-c-darkGray
  border-b-[1px]
  pb-[10px]
  w-full
  text-left
  min-h-[107px]
  sm:min-h-fit
}

.packItem > p {
  @apply
  py-[10px]
}

/* .packItem > .subTitle {
  @apply
  pt-[10px]
} */

.packItem > img {
  @apply
  self-center
  /* py-[34.5px] */
  pt-[10px]
  /* h-[220px] */
}

.packItem > .txt {
  @apply
  border-c-darkGray
  border-b-[1px]
  text-left
  flex-grow
  mb-[10px]
}