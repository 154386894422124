input {
  border-color: #a8a8a8 !important;
  border-width: 2px !important;
}

input:focus {
  border-width: 2px;
  border-color: #02c39a !important;
  box-shadow: none !important;
}

.admin-main {
  color: black;
}
