.about_us_wrapper{
    @apply
    bg-[#F4F1EC]
    /* flex
    items-center
    justify-start */
    /* px-[150px]
    xl:px-[80px]
    sm:px-[16px] */
    w-[80vw]
    max-w-[1140px]
    mx-auto
    /* flex-col */
}

.about_us_wrapper .about_us {
    @apply
    w-full
    max-w-[1140px]
    flex
    mx-auto
    flex-col
    items-start
    justify-start
}